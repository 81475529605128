import React from 'react';
import NotificationsLog from './NotificationsLog';
import ScheduleSettings from './ScheduleSettings';
import TestNotifications from './TestNotifications';
import UpcomingNotifications from './UpcomingNotifications';

function Dashboard({ authToken }) {
  const [refreshKey, setRefreshKey] = React.useState(0); // State to trigger refresh

  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <button onClick={handleRefresh}>Get Upcoming Notifications</button>
      <TestNotifications authToken={authToken} />
      <ScheduleSettings authToken={authToken} />
      <UpcomingNotifications authToken={authToken} refreshKey={refreshKey} />
      <NotificationsLog authToken={authToken} />
    </div>
  );
}

export default Dashboard;
