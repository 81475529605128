// src/components/UpcomingNotifications.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UpcomingNotifications({ authToken, refreshKey }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/upcoming-notifications', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setNotifications(response.data);
    } catch (error) {
      alert('Failed to fetch upcoming notifications');
      console.error('Fetch Upcoming Notifications Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, refreshKey]); // Re-fetch when authToken or refreshKey changes

  return (
    <div>
      <h2>Upcoming Notifications</h2>
      {loading ? (
        <p>Loading...</p>
      ) : notifications.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Type</th>
              <th>Message</th>
              <th>Notification Methods</th>
              <th>Subscription Level</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification, index) => (
              <tr key={index}>
                <td>{notification.userId}</td>
                <td>{notification.notificationType}</td>
                <td>{notification.message}</td>
                <td>
                  {Array.isArray(notification.notification_methods)
                    ? notification.notification_methods.join(', ')
                    : 'N/A'}
                </td>
                <td>{notification.subscription_level || 'N/A'}</td>
                <td>
                  {notification.timestamp
                    ? new Date(notification.timestamp).toLocaleString()
                    : 'N/A'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No upcoming notifications.</p>
      )}
    </div>
  );
}

export default UpcomingNotifications;
