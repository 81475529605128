import React, { useState } from 'react';
import axios from 'axios';

function ScheduleSettings({ authToken }) {
  const [cronTime, setCronTime] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!cronTime) {
      alert('Please enter a valid Cron Time');
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        '/api/update-schedule',
        { newCronTime: cronTime },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      alert('Schedule time updated');
    } catch (error) {
      alert('Failed to update schedule time');
      console.error('Update Schedule Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Update Schedule Time</h2>
      <input
        type="text"
        placeholder="Cron Time"
        value={cronTime}
        onChange={e => setCronTime(e.target.value)}
      />
      <button onClick={handleSubmit} disabled={loading}>
        {loading ? 'Updating...' : 'Update Schedule'}
      </button>
    </div>
  );
}

export default ScheduleSettings;
