import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [authToken, setAuthToken] = useState('');

  return (
    <Router>
      {authenticated ? (
        <Dashboard authToken={authToken} />
      ) : (
        <Login setAuthenticated={setAuthenticated} setAuthToken={setAuthToken} />
      )}
    </Router>
  );
}

export default App;
