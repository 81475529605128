// src/components/TestNotifications.js

import React, { useState } from 'react';
import axios from 'axios';

// UUID validation regex
const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 * Component to send test notifications to a user.
 * @param {string} authToken - JWT token for authentication.
 */
function TestNotifications({ authToken }) {
  const [userId, setUserId] = useState('');
  const [message, setMessage] = useState('');
  const [methods, setMethods] = useState({
    push: true,
    email: true,
    sms: true,
  });
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null); // To display success or error messages

  /**
   * Validates the UUID format.
   * @param {string} uuid - The UUID string to validate.
   * @returns {boolean} - True if valid, false otherwise.
   */
  const isValidUUID = (uuid) => {
    return uuidRegex.test(uuid.trim());
  };

  /**
   * Handles the submission of the test notification form.
   */
  const handleSubmit = async () => {
    const sanitizedUserId = userId.trim();

    if (!sanitizedUserId || !message) {
      setFeedback({ type: 'error', message: 'Please enter both User ID and Message.' });
      return;
    }

    if (!isValidUUID(sanitizedUserId)) {
      setFeedback({ type: 'error', message: 'Invalid User ID format. Please enter a valid UUID.' });
      return;
    }

    setLoading(true);
    setFeedback(null); // Reset feedback

    try {
      const response = await axios.post(
        '/api/send-test-notification',
        { userId: sanitizedUserId, message, methods },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFeedback({ type: 'success', message: 'Test notification sent successfully!' });
    } catch (error) {
      const errorMsg = error.response?.data?.error || error.response?.data?.message || 'Unknown error occurred.';
      setFeedback({ type: 'error', message: `Failed to send test notification: ${errorMsg}` });
      console.error('Send Test Notification Error:', error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles changes to the notification method checkboxes.
   * @param {object} event - The change event from the checkbox.
   */
  const handleCheckboxChange = (event) => {
    setMethods({
      ...methods,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div style={styles.container}>
      <h2>Send Test Notification</h2>
      {feedback && (
        <div
          style={{
            ...styles.feedback,
            backgroundColor: feedback.type === 'success' ? '#d4edda' : '#f8d7da',
            color: feedback.type === 'success' ? '#155724' : '#721c24',
          }}
        >
          {feedback.message}
        </div>
      )}
      <div style={styles.field}>
        <label style={styles.label}>User ID:</label>
        <input
          type="text"
          placeholder="Enter User UUID"
          value={userId}
          onChange={e => setUserId(e.target.value)}
          style={styles.input}
        />
      </div>
      <div style={styles.field}>
        <label style={styles.label}>Message:</label>
        <textarea
          placeholder="Enter Notification Message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          style={styles.textarea}
        ></textarea>
      </div>
      <div style={styles.methods}>
        <label style={styles.checkboxLabel}>
          <input
            type="checkbox"
            name="push"
            checked={methods.push}
            onChange={handleCheckboxChange}
            style={styles.checkbox}
          />
          Push Notification
        </label>
        <label style={styles.checkboxLabel}>
          <input
            type="checkbox"
            name="email"
            checked={methods.email}
            onChange={handleCheckboxChange}
            style={styles.checkbox}
          />
          Email
        </label>
        <label style={styles.checkboxLabel}>
          <input
            type="checkbox"
            name="sms"
            checked={methods.sms}
            onChange={handleCheckboxChange}
            style={styles.checkbox}
          />
          SMS
        </label>
      </div>
      <button onClick={handleSubmit} disabled={loading} style={styles.button}>
        {loading ? 'Sending...' : 'Send Test Notification'}
      </button>
    </div>
  );
}

// Inline styles for basic styling
const styles = {
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  field: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  textarea: {
    width: '100%',
    padding: '8px',
    height: '100px',
    boxSizing: 'border-box',
  },
  methods: {
    marginBottom: '15px',
  },
  checkboxLabel: {
    display: 'block',
    marginBottom: '5px',
  },
  checkbox: {
    marginRight: '10px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  feedback: {
    padding: '10px',
    marginBottom: '15px',
    borderRadius: '4px',
  },
};

export default TestNotifications;
