import React, { useState, useEffect } from 'react';
import axios from 'axios';

function NotificationsLog({ authToken }) {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get('/api/notification-logs', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setLogs(response.data);
      } catch (error) {
        alert('Failed to fetch notification logs');
        console.error('Fetch Logs Error:', error);
      }
    };
    if (authToken) {
      fetchLogs();
    }
  }, [authToken]);

  return (
    <div>
      <h2>Notification Logs</h2>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Type</th>
            <th>Method</th>
            <th>Status</th>
            <th>Message</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {logs.map(log => (
            <tr key={log.id}>
              <td>{log.user_id}</td>
              <td>{log.notification_type}</td>
              <td>{log.notification_method}</td>
              <td>{log.status}</td>
              <td>{log.message}</td>
              <td>{new Date(log.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NotificationsLog;
