import React, { useState } from 'react';
import axios from 'axios';

function Login({ setAuthenticated, setAuthToken }) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    if (!password) {
      alert('Please enter the password');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/api/login', { password });
      const { token } = response.data;
      setAuthToken(token); // Store token in state or context
      setAuthenticated(true);
    } catch (error) {
      alert('Incorrect password or server error');
      console.error('Login error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Admin Login</h2>
      <input
        type="password"
        placeholder="Enter password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <button onClick={handleLogin} disabled={loading}>
        {loading ? 'Logging in...' : 'Login'}
      </button>
    </div>
  );
}

export default Login;
